
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const entityID = ref(props.empty ? '' : 'https://pingone.com/idp/cd-270493788.textmagic')
    const ssoURL = ref(props.empty ? '' : 'https://sso.connect.pingidentity.com/sso/idp/SSO.saml2?idpid')
    const sloURL = ref(props.empty ? '' : 'https://sso.connect.pingidentity.com/sso/idp/SSO.saml2?idpid')
    const certificate = ref('')
    const format = ref<'sso' | 'sso-and-password'>('sso')

    return {
      entityID,
      ssoURL,
      sloURL,
      certificate,
      format,
    }
  },
})
